@each $color, $value in $theme-colors {
    .link-#{$color} {
        color: $value;

        @if $link-shade-percentage != 0 {
            &:hover,
            &:focus {
                color: if(
                    color-contrast($value) == $color-contrast-light,
                    shade-color($value, $link-shade-percentage),
                    tint-color($value, $link-shade-percentage)
                );
            }
        }
    }
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: $breadcrumb-padding-y $breadcrumb-padding-x;
    margin-bottom: $breadcrumb-margin-bottom;
    @include font-size($breadcrumb-font-size);
    list-style: none;
    background-color: $breadcrumb-bg;
    @include border-radius($breadcrumb-border-radius);
}

.breadcrumb-item {
    // The separator between breadcrumbs (by default, a forward-slash: "/")
    + .breadcrumb-item {
        padding-left: $breadcrumb-item-padding-x;

        &::before {
            float: left; // Suppress inline spacings and underlining of the separator
            padding-right: $breadcrumb-item-padding-x;
            color: $breadcrumb-divider-color;
            content: var(
                    --#{$variable-prefix}breadcrumb-divider,
                    escape-svg($breadcrumb-divider)
                )
                #{"/* rtl:"} var(
                    --#{$variable-prefix}breadcrumb-divider,
                    escape-svg($breadcrumb-divider-flipped)
                ) #{"*/"};
        }
    }

    &.active {
        color: $breadcrumb-active-color;
    }
}

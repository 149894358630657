// Row
//
// Rows contain your columns.

@if $enable-grid-classes {
    .row {
        @include make-row();

        > * {
            @include make-col-ready();
        }
    }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
    @include make-grid-columns();
}
